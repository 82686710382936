<template>
  <div>
    <v-tooltip v-if="section !== 11" left color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon
            :color="
              section === 9 && $vuetify.theme.dark === false
                ? 'white'
                : 'secondary'
            "
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span v-if="section === 1 || section === 3">Add User</span>
      <span v-else-if="section === 5">Add Researcher</span>
      <span v-else-if="section === 4 || section === 6 || section === 9"
        >Add Trainee</span
      >
      <span v-else-if="section === 7">Add Other Project User</span>
      <span v-else-if="section === 2 || section === 8">Add RPPR User</span>
      <span v-else>Add Participant</span>
    </v-tooltip>

    <v-tooltip
      v-if="
        section === 11 &&
          usersStore.authUserType !== 4 &&
          usersStore.authUserType !== 7
      "
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="openEntry(usersStore.user.id)">
          <v-icon color="primary">
            fal fa-pencil
          </v-icon>
        </v-btn>
      </template>
      <span>Edit User</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="900"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card max-height="840" flat>
        <v-card-title class="text-h5 secondary--text">
          User Details
          <v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()">
            <v-icon small left> fal fa-times </v-icon>Cancel
          </v-btn>
        </v-card-title>

        <v-card-text v-if="emailCheck !== 1">
          <v-form
            ref="searchForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="searchByEmail()"
          >
            <v-container fluid>
              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="6">
                  <v-row dense>
                    <!--Left Side-->
                    <v-col cols="12" sm="8">
                      <v-text-field
                        v-model="emailAddress"
                        label="Email"
                        placeholder=" "
                        required
                        :rules="rules.email"
                      />
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-btn
                        block
                        rounded
                        color="primary"
                        @click="searchByEmail()"
                      >
                        <v-icon small left> fal fa-check </v-icon>Search
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-text v-if="emailCheck === 1">
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="saveEntry()"
          >
            <v-container fluid>
              <v-row v-if="1 === 2">
                <v-btn block rounded color="primary" @click="testDate()">
                  <v-icon small left> fal fa-check </v-icon>Test
                </v-btn>
              </v-row>
              <v-row v-if="!id" dense>
                <v-col cols="12" sm="12" class="text-center">
                  <span class="text-h6 error--text"
                    >Adding a new user to the system.</span
                  >
                </v-col>
              </v-row>

              <v-row v-else-if="id_participation" dense>
                <v-col cols="12" sm="12" class="text-center">
                  <span class="text-h6 success--text"
                    >User is already associated with this project. Modify or
                    inactivate the current participation under VIEW USER.
                    <br />Then you may proceed to add them to the project with
                    their new role or their new title.</span
                  >
                </v-col>
              </v-row>

              <v-row v-else-if="id && mode === 1" dense>
                <v-col cols="12" sm="12" class="text-center">
                  <span class="text-h6 success--text"
                    >User already exists in the system. Add participation for
                    the user.</span
                  >
                </v-col>
              </v-row>

              <v-row v-else-if="id && mode !== 1" dense>
                <v-col cols="12" sm="12" class="text-center">
                  <span class="text-h6 success--text"
                    >User already exists in the system.</span
                  >
                </v-col>
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="6">
                  <v-row dense>
                    <!--Email-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="email"
                        label="Email"
                        placeholder=" "
                        required
                        :rules="rules.email"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--First Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="name_first"
                        label="First Name"
                        placeholder=" "
                        required
                        :rules="rules.not_empty"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Last Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="name_last"
                        label="Last Name"
                        placeholder=" "
                        required
                        :rules="rules.not_empty"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="section !== 2 && section !== 8 && mode === 1"
                    dense
                  >
                    <!--Password-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="password"
                        label="Password"
                        placeholder=" "
                        :required="mode === 1 ? true : false"
                        :rules="mode === 1 ? rules.password : []"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="section !== 2 && section !== 8 && mode === 1"
                    dense
                  >
                    <!--Password Confirmation-->
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="passwordConfirmation"
                        label="Confirm Password"
                        placeholder=" "
                        :required="mode === 1 ? true : false"
                        :rules="mode === 1 ? rules.confirmPassword : []"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="usersStore.authUser.flag_admin === 1" dense>
                    <!--Admin-->
                    <v-col cols="12" sm="12" md="3">
                      <v-select
                        v-model="flag_admin"
                        :items="valueListsStore.yesNo"
                        label="Site Administrator"
                        placeholder=" "
                        item-text="value"
                        item-value="id"
                        required
                        clearable
                        clear-icon="fal fa-times-circle"
                        :rules="flag_admin === 0 ? [] : rules.not_empty"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="
                      id_participation && usersStore.authUser.flag_admin === 1
                    "
                    dense
                  >
                    <!--Include in emails-->
                    <v-col cols="12" sm="12" md="3">
                      <v-autocomplete
                        v-model="flag_email"
                        :items="valueListsStore.yesNo"
                        label="Include In Mass Emails"
                        placeholder=" "
                        item-text="value"
                        item-value="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="usersStore.authUser.flag_admin === 1" dense>
                    <!--Hide-->
                    <v-col cols="12" sm="12" md="3">
                      <v-select
                        v-model="flag_hide"
                        :items="valueListsStore.yesNo"
                        label="Hide In User Lists"
                        placeholder=" "
                        item-text="value"
                        item-value="id"
                        required
                        :rules="flag_hide === 0 ? [] : rules.not_empty"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="usersStore.authUser.flag_admin === 1" dense>
                    <!--Access-->
                    <v-col cols="12" sm="12" md="3">
                      <v-select
                        v-model="flag_status"
                        :items="valueListsStore.accessStatus"
                        label="Access"
                        placeholder=" "
                        item-text="value"
                        item-value="id"
                        required
                        :rules="flag_status === 0 ? [] : rules.not_empty"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="usersStore.authUser.flag_admin === 1" dense>
                    <!--Delete-->
                    <v-col cols="12" sm="12" md="3">
                      <v-select
                        v-model="flag_show"
                        :items="valueListsStore.yesNo"
                        label="Show In System"
                        placeholder=" "
                        item-text="value"
                        item-value="id"
                        required
                        :rules="flag_show === 0 ? [] : rules.not_empty"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Right Side-->
                <v-col cols="12" sm="6" class="pl-4">
                  <v-row v-if="usersStore.authUser.flag_admin === 1" dense>
                    <!--Project Selector-->
                    <v-col v-if="section === 1" cols="12" sm="12" md="2">
                      <v-autocomplete
                        v-model="id_project"
                        :items="projectsStore.projectsValueList"
                        label="Project"
                        placeholder=" "
                        item-text="code"
                        item-value="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                        @input="pullProjectYears()"
                      />
                    </v-col>

                    <!--User Types-->
                    <v-col
                      v-if="section === 1 || section === 12 || section === 3"
                      cols="12"
                      sm="12"
                      :md="section === 1 ? 10 : 12"
                    >
                      <v-select
                        v-model="flag_user_type"
                        :items="
                          valueListsStore.userTypes.filter((item) => {
                            if (
                              usersStore.authUser.flag_admin === 0 &&
                              (item.value === 'NSF EPSCoR' ||
                                item.value === 'Program Officer')
                            )
                              return false;
                            else return true;
                          })
                        "
                        label="User Type"
                        placeholder=" "
                        item-value="id"
                        item-text="value"
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                        @input="pullResearchers()"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="!id_participation && section === 7" dense>
                    <!--User Types-->
                    <v-col cols="12" sm="12">
                      <v-select
                        v-model="flag_user_type"
                        :items="
                          valueListsStore.userTypesOther.filter((item) => {
                            if (
                              usersStore.authUser.flag_admin === 0 &&
                              (item.value === 'NSF EPSCoR' ||
                                item.value === 'Program Officer')
                            )
                              return false;
                            else return true;
                          })
                        "
                        label="User Type"
                        placeholder=" "
                        item-value="id"
                        item-text="value"
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                        :rules="rules.not_empty"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Classification-->
                    <v-col
                      v-if="!id_participation && flag_user_type === 3"
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-autocomplete
                        v-model="classification"
                        :items="valueListsStore.classificationsTrainees"
                        label="Classification"
                        placeholder=" "
                        item-text="value"
                        item-value="value"
                        clearable
                        clear-icon="fal fa-times-circle"
                        required
                        :rules="rules.not_empty"
                      />
                    </v-col>

                    <!--Other Classification-->
                    <v-col
                      v-if="
                        !id_participation &&
                          flag_user_type === 3 &&
                          classification === 'Other'
                      "
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="classification_other"
                        label="Specify Other Classification"
                        placeholder=" "
                        required
                        :rules="rules.not_empty"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Classification-->
                    <v-col
                      v-if="
                        (!id_participation && flag_user_type === 2) ||
                          (!id_participation && flag_user_type === 1)
                      "
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-autocomplete
                        v-model="classification"
                        :items="valueListsStore.classificationsResearchers"
                        label="Classification"
                        placeholder=" "
                        item-text="value"
                        item-value="value"
                        clearable
                        clear-icon="fal fa-times-circle"
                        required
                        :rules="rules.not_empty"
                      />
                    </v-col>

                    <!--Other Classification-->
                    <v-col
                      v-if="
                        (!id_participation &&
                          flag_user_type === 2 &&
                          classification === 'Other') ||
                          (!id_participation &&
                            flag_user_type === 1 &&
                            classification === 'Other')
                      "
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="classification_other"
                        label="Specify Other Classification"
                        placeholder=" "
                        required
                        :rules="rules.not_empty"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="!id_participation && section !== 11" dense>
                    <!--Start Month-->
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="date_start_month"
                        :items="valueListsStore.monthAbbreviations"
                        item-text="value"
                        item-value="value"
                        label="Participation Start Month"
                        placeholder=" "
                        clearable
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                        required
                        :rules="rules.not_empty"
                      />
                    </v-col>

                    <!--Start Year-->
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="date_start_year"
                        :items="valueListsStore.projectYearList"
                        item-text="value"
                        item-value="value"
                        label="Participation Start Year"
                        placeholder=" "
                        clearable
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                        required
                        :rules="rules.not_empty"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Participation Status-->
                    <v-col
                      v-if="!id_participation && section !== 11"
                      cols="12"
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="participation_flag_status"
                        :items="valueListsStore.activeStatus"
                        label="Participation Status"
                        placeholder=" "
                        item-text="value"
                        item-value="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="
                      !id_participation &&
                        participation_flag_status &&
                        participation_flag_status !== 1
                    "
                    dense
                  >
                    <!--End Month-->
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="date_end_month"
                        :items="valueListsStore.monthAbbreviations"
                        item-text="value"
                        item-value="value"
                        label="Participation End Month"
                        placeholder=" "
                        clearable
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                      />
                    </v-col>

                    <!--End Year-->
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="date_end_year"
                        :items="valueListsStore.projectYearList"
                        item-text="value"
                        item-value="value"
                        label="Participation End Year"
                        placeholder=" "
                        clearable
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Contact PI-->
                    <v-col
                      v-if="
                        !id_participation &&
                          usersStore.authUser.flag_admin === 1 &&
                          flag_user_type === 1
                      "
                      cols="12"
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="flag_contact_pi"
                        :items="valueListsStore.yesNo"
                        label="Contact PI"
                        placeholder=" "
                        item-text="value"
                        item-value="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>

                    <!--Invite-->
                    <v-col cols="12" sm="12" md="3">
                      <v-autocomplete
                        v-if="
                          !id_participation &&
                            section !== 2 &&
                            section !== 8 &&
                            section !== 10 &&
                            section !== 11
                        "
                        v-model="sendInvite"
                        :items="valueListsStore.yesNo"
                        label="Send Email Invite"
                        placeholder=" "
                        item-text="value"
                        item-value="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <!--Researchers List For Trainees-->
                  <v-row v-if="showParticipantsSelector" dense>
                    <v-col cols="12" sm="12">
                      <participants-selector
                        :user-type="1"
                        :section="section === 3 ? 7 : 6"
                        :role-selector="0"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions
          v-if="
            (emailCheck === 1 && usersStore.authUser.flag_admin === 1) ||
              (emailCheck === 1 && !id_participation)
          "
          class="mx-4 pb-4"
        >
          <v-btn block rounded color="primary" @click="saveEntry()">
            <v-icon small left> fal fa-check </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import ParticipantsSelector from "@/components/ParticipantsSelector";

const { mapFields } = createHelpers({
  getterType: "usersStore/getEntryField",
  mutationType: "usersStore/updateEntryField",
});

export default {
  name: "User",
  components: {
    ParticipantsSelector,
  },
  mixins: [mixin],
  props: {
    /*
     1 = Settings Users List,
     2 = RPPR List,
     3 = Project Detail Users List,
     4 = Trainees List (Researcher List Of Trainees),
     5 = Admin Researchers List,
     6 = Admin Trainees List,
     7 = Admin Others Project Users List,
     8 = Admin RPPR List,
     9 = Trainees Dashboard,
     10 = RPPR Researchers List,
     11 = User Detail
     */
    /*1 = User Settings, 2 = User Detail, 3 = */
    section: Number,
    readonly: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      errorMessage: false,
      rules: {
        not_empty: [(v) => !!v || ""],
        email: [(v) => !!v || ""],
        password: [
          (v) => !!v || "Required",
          (v) =>
            (v && v.length >= 8) || "Password must have at least 8 characters",
        ],
        confirmPassword: [
          (v) => !!v || "Required",
          (v) =>
            (v && v === this.password) || "Password confirmation must match",
        ],
      },
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      valueListsStore: (state) => state.valueListsStore,
      projectsStore: (state) => state.projectsStore,
    }),
    ...mapFields([
      // "show",
      "valid",
      "mode",
      "id",

      "sectionStored",

      "name_first",
      "name_last",
      "email",
      "emailAddress",
      "password",
      "passwordConfirmation",
      "flag_status",
      "flag_admin",
      "flag_hide",
      "flag_show",
      "emailCheck",

      "id_participation",
      "id_project",
      "date_start_project",
      "date_start_month",
      "date_start_year",
      "date_end_month",
      "date_end_year",
      "flag_contact_pi",
      "flag_user_type",
      "participation_flag_status",
      "advisors",
      "sendInvite",
      "flag_email",
      "classification",
      "classification_other",
    ]),
    showParticipantsSelector() {
      return (
        (!this.id_participation &&
          this.flag_user_type == 3 &&
          this.id_project) ||
        (!this.id_participation &&
          this.flag_user_type == 8 &&
          this.id_project) ||
        this.section === 8
      );
    },
  },
  methods: {
    async newEntry() {
      let newSection = this.section === 10 ? 101 : this.section;
      await this.$_loadValueLists(Number(5 + "." + newSection));

      const activeUser = this.$store.getters["usersStore/getActiveUser"];
      const activeUserType = this.$store.getters[
        "usersStore/getActiveUserType"
      ];

      this.$store.dispatch("usersStore/resetEntry");
      this.$store.dispatch("participantsStore/clearResearchers");
      this.$store.dispatch("participantsStore/clearParticipantsSelected");
      let project;
      let data;
      if (this.section !== 1 && this.section !== 3 && this.section !== 11) {
        project = this.$store.getters["usersStore/getActiveProject"];
        this.id_project = project.id;
        this.date_start_project = project.date_start;
      }
      if (this.section === 3) {
        project = this.$store.getters["projectsStore/getProject"];
        this.id_project = project.id;
        this.date_start_project = project.date_start;
      }

      /*RPPR*/
      if (this.section === 2 || this.section === 8 || this.section === 10) {
        this.flag_user_type = 8;
      } else if (
        /*Trainee*/
        this.section === 4 ||
        this.section === 6 ||
        this.section === 9
      ) {
        this.flag_user_type = 3;
      } else if (this.section === 5) {
        /*Researcher*/
        this.flag_user_type = 2;
      }

      /*Participant Selector*/
      if (
        this.section === 2 ||
        this.section === 8 ||
        this.section === 10 ||
        this.section === 4 ||
        this.section === 6 ||
        this.section === 9
      ) {
        const researcherData = {
          id_project: this.id_project,
          type: 1,
        };
        await this.$store.dispatch("participantsStore/fetch", researcherData);
        if (activeUserType === 1 || activeUserType === 2) {
          const data = {
            id: activeUser.id,
            role: "",
            name_reverse: activeUser.name_reverse,
            type: 1,
          };
          await this.$store.dispatch("participantsStore/addParticipant", data);
        }
      }

      this.show = true;

      this.$nextTick(() => {
        this.$refs.searchForm.resetValidation();
      });

      // if (this.$refs.searchForm) {
      //   this.$refs.searchForm.resetValidation();
      // }
    },

    async openEntry(id) {
      let project;
      if (this.section !== 1 && this.section !== 3 && this.section !== 11) {
        project = this.$store.getters["usersStore/getActiveProject"];
        this.date_start_project = project.date_start;
      }
      if (this.section === 3) {
        project = this.$store.getters["projectsStore/getProject"];
        this.date_start_project = project.date_start;
      }
      await this.$_loadValueLists(Number(5 + "." + this.section));
      this.$store.dispatch("participantsStore/clearParticipantsSelected");
      let advisors = this.$store.getters["usersStore/getAdvisors"];

      let newAdvisors = [];
      for (var i = 0; i < advisors.length; i++) {
        if (advisors[i].pivot.id_project === project.id) {
          const idx = newAdvisors
            .map((item) => item.id)
            .indexOf(advisors[i].id);
          if (idx < 0) {
            newAdvisors.push(advisors[i]);
          }
        }
      }

      await this.$store.dispatch(
        "participantsStore/setParticipants",
        newAdvisors
      );
      await this.$store.dispatch("usersStore/entry", id);

      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async searchByEmail() {
      if (this.$refs.searchForm.validate()) {
        const entry = this.$store.getters["usersStore/getEntry"];

        if (!this.emailCheck) {
          await this.$store.dispatch("usersStore/readByEmail", entry);
        }
      }
    },

    testDate() {
      this.$_projectDateStart(
        this.date_start_project,
        this.date_start_month,
        this.date_start_year
      );
    },

    async saveEntry() {
      if (
        this.$_projectDateStart(
          this.date_start_project,
          this.date_start_month,
          this.date_start_year
        )
      ) {
        const supervisorsSelected =
          this.$store.getters["participantsStore/getParticipantsSelected"]
            .length > 0;

        if (
          (this.$refs.entryForm.validate() && !this.showParticipantsSelector) ||
          (this.$refs.entryForm.validate() &&
            this.showParticipantsSelector &&
            supervisorsSelected)
        ) {
          if (this.flag_user_type > 3) {
            this.classification = "";
            this.classification_other = "";
          }
          if (this.classification !== "Other") {
            this.classification_other = "";
          }

          this.sectionStored = this.section;
          this.advisors = this.$store.getters[
            "participantsStore/getParticipantsSelected"
          ];

          if (this.flag_user_type !== 3 && this.flag_user_type !== 8) {
            this.advisors = [];
          }

          const entry = this.$store.getters["usersStore/getEntry"];

          /* Create */
          if (this.mode === 1) {
            await this.$store.dispatch("usersStore/create", entry);
            let user = await this.$store.getters["usersStore/getUser"];
            if (this.sendInvite === 1) {
              await this.$_invite(user.id);
            }
          } else {
            /* Edit */
            await this.$store.dispatch("usersStore/update", entry);
          }
          this.cancelEntry();
          if (this.section === 3) {
            /* Grab filters */
            const filters = this.$store.getters["usersStore/getFilters"];
            /* Grab projects based on active filters */
            await this.$store.dispatch("usersStore/fetch", filters);
          } else if (this.section === 5) {
            /* Grab filters */
            const filters = this.$store.getters[
              "usersStore/getResearcherFilters"
            ];
            /* Grab projects based on active filters */
            await this.$store.dispatch("usersStore/fetch", filters);
          } else if (this.section === 4 || this.section === 6) {
            /* Grab filters */
            const filters = this.$store.getters["usersStore/getTraineeFilters"];
            /* Grab projects based on active filters */
            await this.$store.dispatch("usersStore/fetch", filters);
          } else if (this.section === 7) {
            /* Grab filters */
            const filters = this.$store.getters[
              "usersStore/getOtherUserFilters"
            ];
            /* Grab projects based on active filters */
            await this.$store.dispatch("usersStore/fetch", filters);
          } else if (this.section === 8) {
            /* Grab filters */
            const filters = this.$store.getters["usersStore/getRPPRFilters"];
            /* Grab projects based on active filters */
            await this.$store.dispatch("usersStore/fetch", filters);
          }
        }
      } else {
        /* Show error notification */
        let data = {
          color: "error",
          text:
            "Start date must be later than project start of " +
            this.$_formatDate(this.date_start_project),
        };
        this.$store.commit("messagesStore/setMessage", data);
      }
    },

    async pullResearchers() {
      if (this.id_project && this.flag_user_type) {
        const researcherData = {
          id_project: this.id_project,
          type: 1,
        };
        await this.$store.dispatch("participantsStore/fetch", researcherData);
      }
    },

    async pullProjectYears() {
      this.pullResearchers();
      this.date_start_year = "";
      await this.$store.dispatch("projectsStore/read", this.id_project);
      let project = this.$store.getters["projectsStore/getProject"];
      this.date_start_project = project.date_start;
      let year = {
        yearStart: this.$_formatDateYear(project.date_start),
      };
      await this.$store.dispatch("valueListsStore/setProjectYearList", year);
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
